import React from "react";

import ButtonSolid from "../Button/ButtonSolid";

const CTA = ({
  heading,
  headingLevel,

  className,
}) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <div className="container">
      <section
        className={`mb-20 rounded-3xl bg-primary-900 py-20 px-4 md:mb-32 ${className}`}
      >
        <div className="mx-auto max-w-[688px] text-center">
          <HeadingTag className="mb-4 text-white">
            {heading || ["Get in Touch With Our Team"]}
          </HeadingTag>
          <p className="text-primary-50">
            We’re happy to connect with you and answer any questions you might
            have. Reach out today!
          </p>
          <div className="flex flex-wrap items-center justify-center space-y-4 md:flex-nowrap md:space-y-0 md:space-x-4">
            <ButtonSolid
              href="/home-purchase/"
              text="Purchase"
              altStyle={2}
              className="w-full md:w-auto"
            />
            <ButtonSolid
              href="/home-refinance/"
              text="Refinance"
              className="w-full md:w-auto"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CTA;
