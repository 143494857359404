import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import ValueProps from "../components/Repeating/ValueProps";
import CallToAction from "../components/Repeating/CTA";

import ButtonSolid from "../components/Button/ButtonSolid";
import MortgageOptions from "../components/Repeating/MortgageOptions";
import houseSVG from "../images/Landing Page/svg/house.svg";
import cashSVG from "../images/Landing Page/svg/cash.svg";
import bookSVG from "../images/Landing Page/svg/grad-hat.svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
const Page = ({ data }) => {
  const faqs = {
    homeLoan: [
      {
        question: "How Much Equity Can I Tap Into?",
        answer: (
          <>
            <p className="mb-0">
              Lenders will typically allow you to borrow up to 85 percent of
              your home’s equity, which is the appraised value minus what you
              owe. This is what’s called loan-to-value (LTV).
            </p>
          </>
        ),
      },
      {
        question: "What Credit Score Do I Need?",
        answer: (
          <>
            <p className="mb-0">
              You’ll need a good credit score in the mid-600s or higher (over
              700 is ideal). If you need to improve your credit score, you can
              start by paying down credit card balances, catching up on past due
              accounts, and checking your credit report for errors.
            </p>
          </>
        ),
      },
      {
        question: "What About My Debt-to-Income Ratio?",
        answer: (
          <>
            <p className="mb-0">
              When applying for a HELOC, the lower your debt-to-income (DTI)
              ratio, the better (43% or lower is the guide). To calculate your
              DTI, add all your monthly debt payments and divide the total by
              your gross monthly income. Then multiply by 100 to get the
              percentage.
            </p>
          </>
        ),
      },
      {
        question: "What Type of Interest Rate Can I Expect?",
        answer: (
          <>
            <p>
              The interest rate on a HELOC is adjustable (unlike the fixed rate
              you get with a home equity loan) and will fluctuate with the
              market.
            </p>
          </>
        ),
      },
      {
        question: "How Does the Funding Work?",
        answer: (
          <>
            <p className="mb-0">
              You’ll receive a revolving line of credit if approved for a HELOC,
              which means you’ll be able to use that credit line, make payments,
              and take more money out as needed. A HELOC differs from a home
              equity loan, which gives you the loan proceeds all at once.
            </p>
          </>
        ),
      },
      {
        question: "Want More Info?",
        answer: (
          <>
            <p>
              Our mortgage advisors are happy to discuss HELOC in-depth to help
              you determine if it’s a good fit for you and your family.
            </p>
          </>
        ),
      },
    ],
  };
  return (
    <Layout pageType={"Landing"}>
      <SearchEngineOptimization
        title=""
        description=""
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <div className="mb-3.5  text-lg font-extrabold uppercase text-secondary-500">
                Trusted since 1989
              </div>
              <h1 className=" lg:text-[75px] lg:leading-[85px]">
                HELOC.<br></br>For that extra cushion.
              </h1>
              <p className=" text-black lg:text-[30px] lg:leading-[40px]">
                Get a home equity loan and make the most out of your home’s
                value.
              </p>
              <ButtonSolid
                className=" mr-5"
                href="/request-rates/"
                text="Request Rates"
                altStyle={2}
              />
            
            </div>
            <div className="order-1">
              <StaticImage
                src="../images/common/purchase_hero_image.png"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-26">
        <div className="container">
          <header className="mb-10 text-center md:mb-14">
            <h2>Put your equity to work.</h2>
          </header>
          <div className="grid gap-y-8 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
            <div className="flex flex-col justify-between rounded-3xl bg-secondary-500/60 px-2 py-8 shadow-4xl">
              <div className="text-center">
                <img
                  src={houseSVG}
                  alt="Lower Your Payment"
                  className="mx-auto mb-6"
                />

                <h3 className="heading-five">Fund Home Improvements</h3>
              </div>

              <div className="mb-6 text-center ">
                <div className="h-px w-full "></div>
                <p className="mb-0 text-typography-heading">
                  Finish a project you’ve already started or kick off an
                  entirely new one.
                </p>
              </div>

              <ButtonSolid
                href="/refinance/"
                text="Get Financing Now!"
                className="!text-md mx-auto w-9/12"
              />
            </div>
            <div className="flex flex-col justify-between rounded-3xl bg-secondary-500/60 px-2 py-8 shadow-4xl">
              <div className="text-center">
                <img
                  src={cashSVG}
                  alt="Lower Your Payment"
                  className="mx-auto mb-6"
                />

                <h3 className="heading-five">Cover Unexpected Expenses</h3>
              </div>

              <div className="mb-6 text-center ">
                <div className="h-px w-full "></div>
                <p className="mb-0 text-typography-heading">
                  Get access to a sizeable emergency fund when you need it most.
                </p>
                <div className="h-px w-full "></div>
              </div>

              <ButtonSolid
                href="/refinance/"
                text="Get Pre-Approved Now!"
                className="!text-md mx-auto w-9/12"
              />
            </div>
            <div className="flex flex-col justify-between rounded-3xl bg-secondary-500/60 px-2 py-8 shadow-4xl">
              <div className="text-center">
                <img
                  src={bookSVG}
                  alt="Lower Your Payment"
                  className="mx-auto mb-6"
                />

                <h3 className="heading-five">Pay for College</h3>
              </div>

              <div className="mb-6 text-center ">
                <div className="h-px w-full "></div>
                <p className="mb-0 text-typography-heading">
                  Get money to pay tuition and handle other college expenses.
                </p>
                <div className="h-px w-full "></div>
              </div>

              <ButtonSolid
                href="/refinance/"
                text="Learn More!"
                className="!text-md mx-auto w-9/12 "
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid md:grid-cols-12 md:gap-x-10 lg:gap-x-16">
            <div className="space-y-20 md:col-span-12 md:col-start-1 md:space-y-32">
              <div id="section-3">
                <header className="mb-8 md:mb-10">
                  <h2>Your HELOC Questions, Answered.</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-200"
                >
                  {faqs.homeLoan.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-200 py-6 md:py-12"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="mb-0 font-heading font-bold text-typography-heading md:text-xl">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded ? "fa-minus" : "fa-plus"
                                      } text-2xl text-secondary-500 md:text-4xl`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      <ValueProps />

      <div className="container">
        <section className="mb-20 rounded-3xl bg-primary-900 py-20 px-4 md:mb-32">
          <div className="mx-auto max-w-[688px] text-center">
            <h2 className="mb-4 text-white">
              Get in Touch With Our Team Today!
            </h2>
            <p className="text-primary-50">
              We’re happy to connect with you and answer any questions you might
              have. Reach our today!
            </p>
            <div className="flex flex-wrap items-center justify-center space-y-4 md:flex-nowrap md:space-y-0 md:space-x-4">
              <ButtonSolid
                href="/home-purchase/"
                text="Apply Now!"
                className="w-full !text-lg md:w-auto md:min-w-[200px]"
              />
              <ButtonSolid
                href="tel:866-299-1600"
                text="(866) 299-1600"
                altStyle={2}
                className="w-full !text-lg md:w-auto md:min-w-[200px]"
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
